<script lang="ts" setup>
const props = defineProps<{ dark?: boolean, collapsed?: boolean }>()

const src = computed(() => {
  return props.dark ? '/app/logo-dark.svg' : '/app/logo-light.svg'
})

const iconClasses = computed(() => {
  return {
    'h-[24px] w-[24px]': true,
    'text-white': !props.dark,
  }
})
</script>

<template>
  <img
    v-if="!collapsed"
    data-ui="AppLogo"
    :src="src"
    alt="Forgd Logo"
    width="140"
    height="18"
    class="mx-auto"
  >
  <UIcon v-else name="i-forgd-logo" width="24" height="24" alt="Forgd Logo" :class="iconClasses" />
</template>
